import WcFormsAnalyticsFilter from '@components/shared/forms/WcFormsAnalyticsFilter'

const MachineAnalytics = {
  components: {
    WcFormsAnalyticsFilter,
  },

  data() {
    return {
      machine_id: this.$route.params.machine_id,
      apiParams: {
        get: [{ machine_id: this.$route.params.machine_id }, {}],
      },
    }
  },
}

export default MachineAnalytics
