<template lang="pug">
  b-form.wc-forms-analytics-filter(
    @submit.stop.prevent='filterSubmit'
    inline
    novalidate)

    fieldset.fieldset-inline.justify-content-end.w-100(v-if='formShow' :disabled='formDisable')

      b-form-group#form-analytics-filter-period.w-sm-50.w-lg-25
        multiselect#analytics-filter-period.shadow-sm.custom-select.mr-sm-3(
          name='period'
          v-model='analyticsPeriodSelected'
          :options='analyticsPeriodOptions'
          :close-on-select='true'
          :clear-on-select='false'
          :placeholder="$t('analytic.placeholders.period')"
          label='label'
          track-by='value'
          :selectLabel="$t('shared.forms.multiselect.select_label')"
          :selectedLabel="$t('shared.forms.multiselect.selected_label')"
          :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
          :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
          @input='onPeriodChange')
          span(slot='noResult')
            | {{ $t('shared.forms.multiselect.no_result') }}
          span(slot='noOptions')
            | {{ $t('shared.forms.multiselect.no_options') }}

      b-form-group#form-analytics-filter-range.w-sm-50.w-lg-25
        b-input-group.mr-sm-3
          template(#append)
            b-button.shadow-sm(
              variant='loader'
              data-toggle)
              i.fal.fa-fw.fa-calendar
          flat-pickr#analytics-filter-range.form-control.shadow-sm.readonly-white(
            name='range'
            ref='flatpickr'
            v-model.trim='form.helpers.range'
            :config='form.datetime.range.config'
            @on-open='onRangeOpen'
            @on-change='onRangeChange'
            @on-close='onRangeClose'
            trim)
        b-form-text
          i.fal.fa-fw.fa-info-circle
          |  {{ $t('analytic.helpers.range') }}

      b-form-group#form-analytics-filter-buttons
        b-button-group
          b-button.form-analytics-filter-buttons-submit.shadow-sm.text-white(
            type='submit'
            variant='secondary'
            :disabled='formDisable')
            i.fal.fa-fw.fa-analytics
            |  {{ $t('shared.actions.search') }}
          b-button.form-analytics-filter-buttons-clear.shadow-sm(
            variant='primary'
            :disabled='formDisable'
            :title="$t('shared.actions.reset')"
            @click.stop.prevent='filterClear')
            i.fal.fa-fw.fa-redo
</template>

<script>
import { Form, mapSelects } from '@common/form'
import FlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'wc-forms-analytics-filter',
  mixins: [Form],
  props: {
    data: Object,
    formApiBase: {
      type: Object,
      required: true,
    },
    formApiModel: {
      type: String,
      required: true,
    },
    formApiParams: {
      type: Object,
      required: true,
    },
    periodDefault: {
      type: String,
      default: 'last_12_hours',
    },
    rangeMinHours: {
      type: Number,
      default: 12,
    },
    rangeMaxDays: {
      type: Number,
      default: 30,
    },
    rangeMinMonths: {
      type: Number,
      default: 2,
    },
    rangeMinutesIncrement: {
      type: Number,
      default: 5,
    },
  },
  components: {
    FlatPickr,
  },
  computed: {
    ...mapSelects([
      {
        name: 'analyticsPeriod',
        default: 'attributes.period',
        attribute: 'attributes.period',
        validation: 'this.form.data',
        collection: { data: 'periods.data', key: 'id', value: 'attributes.name' },
      },
    ]),
    apiParams() {
      return this.formApiParams
    },
  },
  methods: {
    onPeriodChange(value, id) {
      this.formSelectOption(value, id)
      if (!value) return

      const now = new Date()
      const nstart = new Date(new Date(now).setHours(0, 0, 0, 0))
      const nend = new Date(new Date(now).setHours(23, 59, 59))
      let range = null

      /* Set actual min max dates to the instance. */
      this.flatpickrReconfig(now)

      switch (value.value) {
        case 'last_hour':
          range = [new Date(new Date(now).setHours(now.getHours() - 1)), now]
          break
        case 'last_12_hours':
          range = [new Date(new Date(now).setHours(now.getHours() - 12)), now]
          break
        case 'today':
          range = [nstart, now]
          break
        case 'yesterday':
          range = [
            new Date(nstart.setDate(nstart.getDate() - 1)),
            new Date(nend.setDate(nend.getDate() - 1)),
          ]
          break
        case 'last_7_days':
          range = [new Date(nstart.setDate(nstart.getDate() - 7)), now]
          break
        case 'this_week':
          range = [
            new Date(
              nstart.setDate(nstart.getDate() - nstart.getDay() + (nstart.getDay() == 0 ? -6 : 1))
            ),
            now,
          ]
          break
        case 'previous_week':
          range = [
            new Date(
              nstart.setDate(nstart.getDate() - nstart.getDay() + (nstart.getDay() == 0 ? -13 : -6))
            ),
            new Date(nend.setDate(nend.getDate() - nend.getDay())),
          ]
          break
        case 'last_30_days':
          range = [new Date(nstart.setDate(nstart.getDate() - 30)), now]
          break
        case 'this_month':
          range = [new Date(nstart.getFullYear(), nstart.getMonth(), 1), now]
          break
        case 'previous_month':
          range = [
            new Date(nstart.getFullYear(), nstart.getMonth() - 1, 1),
            new Date(nend.getFullYear(), nend.getMonth(), 0, 23, 59, 59),
          ]
          break
        default:
          range = [new Date(new Date(now).setHours(now.getHours() - this.rangeMinHours)), now]
          break
      }
      this.$refs.flatpickr.fp.setDate(range, false)
      this.$refs.flatpickr.fp.redraw()

      /* Reverse if from time is after to time. */
      let dates = this.$refs.flatpickr.fp.selectedDates
      if (dates[0] > dates[1]) dates = dates.reverse()

      /* Set form attributes with right formatted values. */
      const format = this.form.datetime.range.config.dateFormat
      this.form.data.attributes.from = this.$refs.flatpickr.fp.formatDate(dates[0], format)
      this.form.data.attributes.to = this.$refs.flatpickr.fp.formatDate(dates[1], format)
    },
    onRangeOpen() {
      this.flatpickrReconfig()
    },
    onRangeChange(dates, range, instance) {
      if (dates.length === 1) {
        const dS = new Date(new Date(dates[0]).setHours(0, 0, 0))
        const dE = new Date(new Date(dates[0]).setHours(23, 59, 59))
        const dBefore = new Date(new Date(dS).setDate(dS.getDate() - this.rangeMaxDays + 1))
        const dLater = new Date(new Date(dE).setDate(dE.getDate() + this.rangeMaxDays - 1))
        if (instance.config.minDate < dBefore) instance.set('minDate', dBefore)
        if (instance.config.maxDate > dLater) instance.set('maxDate', dLater)
      }

      if (dates.length === 2) {
        if (dates[0] > dates[1]) dates = dates.reverse()
        const format = this.form.datetime.range.config.dateFormat
        this.form.data.attributes.from = instance.formatDate(dates[0], format)
        this.form.data.attributes.to = instance.formatDate(dates[1], format)
        this.form.data.attributes.period = null
        this.flatpickrReconfig()
      }
    },
    onRangeClose(dates) {
      if (dates.length !== 2) {
        this.filterClear()
      }
    },
    flatpickrReconfig(now = new Date()) {
      this.$refs.flatpickr.fp.set(
        'minDate',
        new Date(new Date(now).setMonth(now.getMonth() - this.rangeMinMonths))
      )
      this.$refs.flatpickr.fp.set('maxDate', new Date(new Date(now).setHours(23, 59, 59, 999)))
      this.$refs.flatpickr.fp.set('defaultDate', [
        new Date(new Date(now).setHours(now.getHours() - this.rangeMinHours)),
        now,
      ])
    },
    filterSubmit() {
      this.$emit('update:data', Object.assign({}, this.form.data.attributes))
    },
    filterClear() {
      const now = new Date()
      this.flatpickrReconfig(now)
      this.$refs.flatpickr.fp.setDate(
        [new Date(new Date(now).setHours(now.getHours() - this.rangeMinHours)), now],
        false
      )
      this.$refs.flatpickr.fp.redraw()
      this.form.data.attributes.from = null
      this.form.data.attributes.to = null
      this.form.data.attributes.period = this.periodDefault
      this.$emit('update:data', Object.assign({}, this.form.data.attributes))
    },
  },
  data() {
    const now = new Date()

    return {
      apiBase: this.formApiBase,
      apiModel: this.formApiModel,
      formBootstrapDefaults: {
        all: {
          data: {
            attributes: {
              period: this.periodDefault,
              from: null,
              to: null,
            },
          },
          helpers: {
            range: null,
          },
          datetime: {
            range: {
              config: {
                mode: 'range',
                wrap: true,
                allowInput: false,
                enableTime: true,
                time_24hr: true,
                minDate: new Date(new Date(now).setMonth(now.getMonth() - this.rangeMinMonths)),
                maxDate: new Date(new Date(now).setHours(23, 59, 59, 999)),
                minuteIncrement: this.rangeMinutesIncrement,
                ariaDateFormat: this.$t('time.formats.picker').replace(/%/gi, ''),
                dateFormat: this.$t('time.formats.picker').replace(/%/gi, ''),
                locale:
                  this.$store.getters['ui/getLocale'] !== this.$wc.conf.base.default_locale
                    ? require(`flatpickr/dist/l10n/${this.$store.getters['ui/getLocale']}.js`)
                        .default[this.$store.getters['ui/getLocale']]
                    : this.$wc.conf.base.default_locale,
                defaultDate: [
                  new Date(new Date(now).setHours(now.getHours() - this.rangeMinHours)),
                  now,
                ],
              },
            },
          },
        },
      },
    }
  },
}
</script>
