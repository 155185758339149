import WcCardTabs from '@components/shared/WcCardTabs'

const MachineTabs = {
  components: {
    WcCardTabs,
  },

  computed: {
    tabs() {
      return [
        {
          name: this.$t('machine.telemetry.title'),
          route: {
            name: 'machines-telemetry',
            params: { machine_id: this.machine_id },
          },
        },
        {
          name: this.$t('machine.analytics.title'),
          route: {
            name: 'machines-analytics',
            params: { machine_id: this.machine_id },
          },
        },
        {
          name: this.$t('activerecord.models.machine/report.other'),
          route: {
            name: 'machines-reports',
            params: { machine_id: this.machine_id },
          },
        },
      ]
    },
  },
}

export default MachineTabs
